import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PagesModule } from "./pages/pages.module";
import { HttpClientModule } from "@angular/common/http";
import { LoggerModule } from "ngx-logger";
import { SessionService } from "./core/session/session.service";
import { sessionProviderFactory } from "./core/session/session.module";
import { InjectorResolver } from "./core/injector/injector.service";

import { NgxPermissionsModule } from "ngx-permissions";
import { MaterialModule } from "./shared/material/material-module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    LoggerModule.forRoot(null),
    PagesModule,
    MaterialModule,
  ],
  providers: [
    SessionService,
    {
      provide: APP_INITIALIZER,
      useFactory: sessionProviderFactory,
      deps: [InjectorResolver, SessionService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
