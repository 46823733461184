import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { NgxPermissionsGuard, NgxPermissionsModule } from 'ngx-permissions';
import { SessionGuard } from '../core/session/session.guard';


const modules = [];

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },

  // Boot
  {
    path: 'loading',
    loadChildren: () =>
      import('./loading/loading.module').then((m) => m.LoadingModule),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./logout/logout.module').then((m) => m.LogoutModule),
  },

  {
    path: '',
    loadChildren: () => import('../pages/home/home.module').then((m) => m.HomeModule)
  },
  {
    path: "register",
    loadChildren: () =>
      import("./sign-up/sign-up.module").then(
        (m) => m.SignUpModule
      ),
    
  }

  // DO NOT ADD ANYTHING ABOVE UNLESS U KNOW WHAT U R DOING

  // Authenticated
  // {
  //   path: 'auth',
  //   loadChildren: () => import('./authenticated/authenticated.module').then((m) => m.AuthenticatedModule),
  // },

];

@NgModule({
  declarations: [
  ],
  imports: [
    modules,
    RouterModule.forRoot(routes, { useHash: false, enableTracing: false, }), // UseHash should be false, for msal to work
    NgxPermissionsModule.forChild()
  ],
  providers: [NgxPermissionsGuard, SessionGuard],
  exports: [RouterModule],
})
export class PagesModule {
  constructor(private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,) {
      this.iconRegistry.addSvgIcon(
        "my_referral",
        this.sanitizer.bypassSecurityTrustResourceUrl(
          "assets/custom/my_referral.svg"
        )
      );
  }
}
