import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import * as moment from "moment";
import { NgxPermissionsObject, NgxPermissionsService } from "ngx-permissions";
import { Observable, Subject } from "rxjs";
import { CustomUtilityService } from "src/app/shared/common/services/custom-utility.service";
import { SnackBarService } from "src/app/shared/common/services/snack-bar.service";
import { ApiResponse, Subscriptions } from "src/app/utility/common.model";
import { IDGenerator } from "src/app/utility/id-generator.util";
import { AppLocalStorage } from "src/app/utility/local-storage.util";
import { environment } from "src/environments/environment";
import { AppError } from "../error/error.model";
import { Store } from "../store/store";
import {
  AuthTokenResponse,
  LoginCredentials,
  LogoutResponse,
  Session,
  SessionAccount,
} from "./session.model";
import {
  SessionCreatingErrorState,
  SessionCreatingState,
  LogoutLoadingState,
  LogoutLoadedState,
  LogoutErrorState,
} from "./session.state";

@Injectable({
  providedIn: "root",
})
export class SessionService extends Store.AbstractService {
  private static RESTRICTED_REDIRECT_URLS: string[] = [
    "/loading",
    "/login",
    "/logout",
    "/register",
  ];

  // sessionValidityTimer: NodeJS.Timer;
  sessionValidityTimer: any;

  private redirectURL: string;
  private redirectToken: string;
  session: Session;
  account: SessionAccount;
  tabSessionId: string;

  private subscriptions: Subscriptions = {
    sessionValidate: null,
  };

  constructor(
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private location: Location,
    private customUtilityService: CustomUtilityService,
    private snackbar: SnackBarService
  ) {
    super();
    const me = this;

    me.tabSessionId = IDGenerator.newId();

    me.router.events.subscribe((re: RouterEvent) => {
      me.onRouterEvent(re);
    });

    window["getAccessToken"] = () => {
      return me.session.token;
    };
  }

  init() {
    const me = this;
    me.setRedirectURL();
    return new Promise<void>((resolve, reject) => {
      me.initSession(resolve, reject);
    });
  }

  redirectToLoginPage() {
    const me = this;
    // me.setRedirectURL();
    me.router.navigate(["/login"]);
  }

  redirectToHomePage() {
    const me = this;
    let redirectTo = "/overview";
    // let redirectTo = '/auth/ask-allpros';
    if (me.redirectURL) {
      redirectTo = me.redirectURL;
    }

    me.router.navigate([redirectTo]);
  }

  private setRedirectURL(url?: string) {
    const me = this;
    let redirectURL: string = url || me.location.path();

    me.redirectToken = IDGenerator.newId();

    if (SessionService.RESTRICTED_REDIRECT_URLS.indexOf(redirectURL) !== -1) {
      redirectURL = null;
    }

    me.redirectURL = redirectURL;
  }

  private isSSORequest(): boolean {
    return window.location.hash.startsWith("#id_token");
  }

  public login(credentials: LoginCredentials): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new SessionCreatingState());
    }, 0);

    me.controller
      .post<ApiResponse<AuthTokenResponse>>(
        environment.api.session.login.endpoint,
        credentials
      )
      .subscribe(
        (data: ApiResponse<AuthTokenResponse>) => {
          if (data) {
            me.session = {
              token: data.data.accessToken,
              id: IDGenerator.newId(),
              expiry: moment().add(1, "day").toDate(),
            };

            AppLocalStorage.set("SESSION", "SESSION", me.session);
            AppLocalStorage.set("SESSION", "USER", data.data);
            if (localStorage.getItem("first_time_user") === null) {
              localStorage.setItem("first_time_user", JSON.stringify(true));
            }
            this.loadPermissions(data.data.roles);
            me.snackbar.openSnackBar(data.message, 3000);
            // me.redirectToHomePage();
            me.router.navigate(['/overview']);

            output.complete();
          } else {
            console.error("No Token Received");
            setTimeout(() => {
              output.error(
                new SessionCreatingErrorState(new AppError("No Token Received"))
              );
              output.complete();
            }, 0);
          }
        },
        (e: any) => {
          setTimeout(() => {
            output.error(new SessionCreatingErrorState(e.error));
            output.complete();
          }, 0);
        }
      );

    return output;
  }

  public logout(): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new LogoutLoadingState());
    }, 0);

    me.controller
      .get(environment.api.session.logout.endpoint, { Authorization: true })
      .subscribe(
        (data: LogoutResponse) => {
          if (data) {
            output.next(new LogoutLoadedState(data));
            output.complete();
          }
        },
        (e: Error) => {
          console.error(e);
          setTimeout(() => {
            output.error(new LogoutErrorState(AppError.fromError(e)));
            output.complete();
          }, 0);
        }
      );

    return output;
  }

  // RolePermission[]
  public getRolePermissionsFor(code: string): any[] {
    const me = this;
    if (me.account && me.account.permissions && me.account.permissions[code]) {
      return Object.values(me.account.permissions[code]);
    }
    return null;
  }

  private startSessionValidation() {
    const me = this;
    me.stopSessionValidation();
    me.sessionValidityTimer = setTimeout(() => {
      me.validateSession();
    }, 60000);
  }

  private stopSessionValidation() {
    const me = this;
    if (me.sessionValidityTimer) {
      clearTimeout(me.sessionValidityTimer);
    }
  }

  private validateSession() {
    const me = this;

    console.debug("Validating Session");

    const path = environment.api.auth.validate.endpoint;
    me.controller.get(path, null, undefined, { Authorization: true }).subscribe(
      (data: any) => {
        me.startSessionValidation();
      },
      (e: any) => {
        me.router.navigate(["logout"]);
      }
    );
  }

  private initSession(resolve: () => void, reject: (reason: AppError) => void) {
    const me = this;
    const session: Session = AppLocalStorage.get(
      "SESSION",
      "SESSION"
    ) as Session;
    const userData = AppLocalStorage.get("SESSION", "USER");

    if (me.isSessionActive(session)) {
      this.loadPermissions(userData?.roles);
      me.session = session;
      resolve();
      me.redirectToHomePage();
    } else {
      me.router.navigate(["login"]);
      resolve();
    }
    if (me.isSSORequest()) {
      resolve();
      return;
    }
  }

  public isActive(): boolean {
    // return true;

    return this.isSessionActive(this.session);
  }

  public clear(): Observable<void> {
    const output = new Subject<void>();
    const me = this;

    // Clear session local storage
    AppLocalStorage.clear("SESSION", "SESSION");

    // Stop session validation check
    me.stopSessionValidation();

    // Reset Session
    me.session = null;

    setTimeout(() => {
      output.next();
    });

    return output;
  }

  public loadPermissions(
    roles: { id: string; name: string; permissions: any[] }[]
  ): void {
    const allPermissions = new Set<string>();

    roles.forEach((role) => {
      if (role.permissions && role.permissions.length > 0) {
        role.permissions.forEach((permission: { name: string; id: string }) => {
          allPermissions.add(permission.name);
        });
      }
    });
    // Convert the Set back to an array if needed
    this.permissionsService.loadPermissions(Array.from(allPermissions));
  }

  public hasPermissionsAll(permissions: string[]): boolean {
    const me = this;
    const sessionPermissions: NgxPermissionsObject =
      me.permissionsService.getPermissions();
    let flag = true;

    for (const permission of permissions) {
      if (!sessionPermissions[permission]) {
        flag = false;
        break;
      }
    }

    return flag;
  }

  private isSessionActive(session: Session): boolean {
    return !!session;
  }

  private onRouterEvent(event: RouterEvent) {
    const me = this;

    if (event instanceof NavigationEnd) {
      switch (event.url) {
        case "/loading":
          me.redirectToken = null;
          break;
      }
    }
  }
}
