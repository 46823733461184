import { Component, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  loggedIn = false;

  socket: WebSocket;

  constructor(
    
  ) {
    window.moment = moment;
  }

  ngOnInit() {


  }

}
